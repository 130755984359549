.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header-right {
  float: right;
}

.header-active {
  background-color: orange;
  size: 100%;
}

a {
  text-decoration: none;
  color: white;
  font-size: 20px;
  font-weight: 700;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 22px;
  padding-bottom: 22px;
}

a:hover {
  background-color: orange;
}

.each-slide>div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 580px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.yuanqushun {
  font-size: 22px;
  text-indent: 2em;
  color: rgb(220, 220, 220);
  line-height: 1.8;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 30%;
  margin-top: 10px;
  text-align: justify;
}

@media only screen and (max-width: 600px) {
  .each-slide>div {
    height: 350px !important;
    width: 100% !important;
    background-size: cover !important;
  }

  .shunIntro>p {
    font-size: 16px !important;
    padding-bottom: 10px;
    padding-top: 7% !important;
  }

  .yuanqushun {
    padding-top: 0;
  }
}

